import { IUserSlice } from 'src/store/user/userSlice.contracts';

const setAuthStorage = (userSlice: IUserSlice) => {
	localStorage.setItem(
		process.env.REACT_APP_APP_NAME ?? 'Intelekt-CA',
		JSON.stringify({
			bcSalesPersonCode: userSlice.bcSalesPersonCode,
			branches: userSlice.branches,
			status: userSlice.status,
			accessToken: userSlice.accessToken,
			refreshToken: userSlice.refreshToken,
			name: userSlice.name,
			error: userSlice.error,
			role: userSlice.role
		})
	);
};

const getAuthStorage = () => {
	return localStorage.getItem(process.env.REACT_APP_APP_NAME ?? 'Intelekt-CA');
};

const removeAuthStorage = () => {
	return localStorage.removeItem(process.env.REACT_APP_APP_NAME ?? 'Intelekt-CA');
};

export { setAuthStorage, getAuthStorage, removeAuthStorage };
