import { createSlice } from '@reduxjs/toolkit';
import { persistAuth } from 'src/features/authentication/utils/persistAuth';
import { RootState } from '../store';
import { IUserSlice } from './userSlice.contracts';
import { setAuthStorage } from 'src/features/authentication/utils/setAuthStorage';

const initialState: IUserSlice = {
	name: null,
	firebaseUser: null,
	status: 'idle',
	accessToken: null,
	refreshToken: null,
	error: null,
	bcSalesPersonCode: null,
	branches: null,
	role: null
};

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		/**
		 * Dispatch resetUser to reset the redux store. Handy when clearing user sessions when logging out.
		 */
		resetUser(state) {
			state = initialState;
		},
	},
	extraReducers(builder) {
		builder
			//########################### Persist Auth ###########################
			.addCase(persistAuth.pending, (state, action) => {
				state.status = 'loading';
			})
			.addCase(persistAuth.fulfilled, (state, action) => {
				state.bcSalesPersonCode = action.payload.bcSalesPersonCode;
				state.branches = action.payload.branches;
				state.name = action.payload.name;
				state.status = action.payload.status;
				state.accessToken = action.payload.accessToken;
				state.refreshToken = action.payload.refreshToken;
				state.firebaseUser = action.payload.firebaseUser;
				state.error = action.payload.error;
				state.role = action.payload.role;
				setAuthStorage(state);
			})
			.addCase(persistAuth.rejected, (state, action) => {
				state.bcSalesPersonCode = null;
				state.branches = null;
				state.name = null;
				state.status = 'error';
				state.accessToken = null;
				state.refreshToken = null;
				state.firebaseUser = null;
				state.error = action.error.message;
				state.role = null;
				setAuthStorage(state);
			})
	},
});

export default userSlice.reducer;

export const selectUser = (state: RootState): typeof initialState => state.user;
export const selectUserId = (state: RootState) => state.user?.firebaseUser?.uid;
