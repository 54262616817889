import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import { Stack, Button } from '@mui/material';
import { Icon } from 'features';

export type StepperActionsProps = {
	previousButtonText?: string | null;
	previousButtonOnClick?: (() => void) | null;
	exitButtonText?: string | null;
	exitButtonOnClick?: (() => void) | null;
	nextButtonText?: string | null;
	nextButtonOnClick?: (() => void) | null;
	nextButtonProps?: LoadingButtonProps;
};

export const StepperActions = ({
	previousButtonText,
	previousButtonOnClick,
	exitButtonText,
	exitButtonOnClick,
	nextButtonText,
	nextButtonOnClick,
	nextButtonProps,
}: StepperActionsProps) => {
	return (
		<Stack direction={'row'} mt={2} gap={2}>
			{previousButtonOnClick && (
				<Button
					variant="contained"
					size="small"
					onClick={() => previousButtonOnClick()}
					startIcon={<Icon icon={'UilArrowLeft'} />}
				>
					{previousButtonText ?? 'Previous'}
				</Button>
			)}
			{exitButtonOnClick && (
				<Button
					variant="outlined"
					size="small"
					onClick={() => exitButtonOnClick()}
				>
					{exitButtonText ?? 'Save & Exit'}
				</Button>
			)}
			{(nextButtonOnClick || nextButtonProps) && (
				<LoadingButton
					variant="contained"
					size="small"
					onClick={() => {
						if (nextButtonOnClick) nextButtonOnClick();
					}}
					endIcon={<Icon icon="UilArrowRight" />}
					{...nextButtonProps}
				>
					{nextButtonText ?? 'Continue'}
				</LoadingButton>
			)}
		</Stack>
	);
};
