import { createApi } from '@reduxjs/toolkit/dist/query/react/index';
import { baseQueryWithTokenExpirationCheck } from './config/query.config';
import { TAG_TYPES } from './config/apiTags';
import { RestApiResponseWithData } from './types/apiResponse.types';
import { ApiStatus } from './types/apiStatus.types';
import { User } from './types/user.types';

export const baseApi = createApi({
	reducerPath: 'baseApi',
	baseQuery: baseQueryWithTokenExpirationCheck,
	tagTypes: [...TAG_TYPES],
	endpoints: (builder) => ({ 
		// API Status
		getApiStatus: builder.query<RestApiResponseWithData<ApiStatus>, void>({
			query: () => 'SystemStatus',
		}),

		// CurrentUser
		getCurrentUser: builder.query<RestApiResponseWithData<User>, string>({
			query(token) {
				return {
					url: 'User/CurrentUser',
					method: 'GET',
					headers: { Authorization: `Bearer ${token}` },
				};
			},
		}),
	}),
});

export const {
	useGetApiStatusQuery
} = baseApi;