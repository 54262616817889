// Rounds a number to 3 decimals, but only if necessary
// Interestingly complicated in Javascript:
// https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
// Examples:
// 10 -> 10
// 1.7777777 -> 1.78
// 9.13 -> 9.13
export const roundToThreeDecimals = (number: number) => {
    // Check the number is not falsy and is in fact a number
    if (number && !isNaN(number)) {
        //@ts-ignore
        return +(Math.round(number + "e+3")  + "e-3");
    } else {
        return number;
    }
}

export const convertMillilitresToLitres = (number: number) => {
    if (number) {
        return number / 1000;
    } else {
        return number;
    }
}

export const convertgramsToKilograms = (number: number) => {
    if (number) {
        return number / 1000;
    } else {
        return number;
    }
}