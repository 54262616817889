import { Box, Stack, Typography, useTheme } from '@mui/material';
import { Button, Icon, IconButton } from 'features';
import { useCallback, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

const convertToMegaBytes = (bytes: number) =>
	Math.round((bytes / 1000000) * 100) / 100;

export interface UploaderFile {
	file: File;
	existing: boolean;
}

interface ImageUploaderProps {
	title?: string;
	files: UploaderFile[];
	setFiles: React.Dispatch<React.SetStateAction<UploaderFile[]>>;
	maxFiles?: number;
	onFilesUpload?: (files: File[]) => void;
	onRemoveFileClick?: (fileToRemove: UploaderFile) => void;
	accept?: Accept;
	disabled?: boolean;
}

export const ImageUploader = ({
	title,
	files,
	setFiles,
	maxFiles,
	onFilesUpload,
	onRemoveFileClick,
	accept,
	disabled,
}: ImageUploaderProps) => {
	const theme = useTheme();
	const [fileLimit, setFileLimit] = useState(false);

	const onDrop = useCallback(
		(newFiles: File[]) => {
			const uploaded: UploaderFile[] = [...files];
			const maxCount = maxFiles ? maxFiles : 10;
			let limitExceeded = false;

			newFiles.some((newFile: File) => {
				// check that the file does not already exist:
				const fileExist = uploaded.some((f) => f.file.name === newFile.name);
				if (!fileExist) {
					uploaded.push({ file: newFile, existing: false });
					if (uploaded.length === maxCount) setFileLimit(true);
					if (uploaded.length > maxCount) {
						toast.warning(`You can only add a maximum of ${maxCount} images`);
						setFileLimit(false);
						limitExceeded = true;
						// return true to break out of the loop:
						return true;
					}
				} else {
					toast.warning('Cannot add duplicate file');
				}
			});

			if (!limitExceeded) {
				setFiles(uploaded);
			}
		},
		[files]
	);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: accept ?? {},
		disabled: disabled ?? false,
		multiple: true,
	});

	const clearSingleFile = (fileToRemove: UploaderFile) => {
		if (onRemoveFileClick) {
			onRemoveFileClick(fileToRemove);
		} else {
			const filesToUpdate = [...files].filter(
				(file) => file.file.name !== fileToRemove.file.name
			);
			setFiles(filesToUpdate);
		}

		if (fileLimit) setFileLimit(false);
	};

	// const clearAllFiles = () => {
	//     setFiles([]);
	//     setFileLimit(false);
	// };

	return (
		<>
			{title && (
				<Typography variant="bodyBold" mb="-10px">
					{title}
				</Typography>
			)}
			<Stack direction="row" justifyContent="space-between" gap={2}>
				<Box
					{...getRootProps()}
					height="331px"
					width={files.length === 0 ? '100%' : '80%'}
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					gap={2}
					p={2}
					style={{
						border: `1px solid ${theme.colors.alpha.grey[2]}`,
						borderRadius: '16px',
					}}
				>
					<input {...getInputProps()} />
					<Icon icon="UilUploadAlt" fontSize="large" color="primary" />
					{isDragActive ? (
						<Typography variant="h5">Drop files here ...</Typography>
					) : (
						<Stack alignItems="center">
							<Typography variant="h5" sx={{ fontWeight: '300px' }}>
								Drag and Drop files
							</Typography>
							<Typography variant="h5">or</Typography>
							<Button
								variant="outlined"
								color="secondary"
								sx={{ mt: 2, paddingX: 6 }}
							>
								Browse...
							</Button>
						</Stack>
					)}
				</Box>
				{files.length !== 0 && (
					<Stack height="331px" width="100%" gap={2} overflow="auto">
						{files.map((file) => (
							<Stack
								direction="row"
								key={file.file.name}
								justifyContent="space-between"
								gap={1}
							>
								<Icon icon="UilImageV" />
								<Typography>{file.file.name}</Typography>
								<Typography sx={{ color: theme.colors.alpha.grey[3] }}>
									{convertToMegaBytes(file.file.size)} MB
								</Typography>
								<IconButton
									icon="UilTrash"
									sx={{ mr: 1 }}
									onClick={() => clearSingleFile(file)}
								/>
							</Stack>
						))}
					</Stack>
				)}
			</Stack>
		</>
	);
};
