import { createAsyncThunk } from '@reduxjs/toolkit'
import { IUserSlice } from 'src/store/user/userSlice.contracts';
import { getAuthStorage } from './setAuthStorage';

export const persistAuth = createAsyncThunk(
    'user/persistAuth',
    async (params: { userAuth: Partial<IUserSlice> }, { rejectWithValue }) => {
        try {
            if (!params.userAuth.name) {
				let user: IUserSlice = {
					bcSalesPersonCode: null,
					branches: null,
					name: null,
					firebaseUser: null,
					status: 'idle',
					error: null,
					accessToken: null,
					refreshToken: null,
					role: null,
					...params.userAuth,
				};

				const userStorage = getAuthStorage();

				if (userStorage) {
					const localStorageUser: IUserSlice = JSON.parse(userStorage);
					const bcSalesPersonCode = params.userAuth.bcSalesPersonCode ? params.userAuth.bcSalesPersonCode : localStorageUser.bcSalesPersonCode
					const branches = params.userAuth.branches ? params.userAuth.branches : localStorageUser.branches

					user = {
						...user,
						bcSalesPersonCode: bcSalesPersonCode,
						branches: branches,
						name: params.userAuth.name ?? localStorageUser?.name,
						accessToken:
							(await params.userAuth.firebaseUser?.getIdToken()) ??
							localStorageUser.accessToken,
						refreshToken:
							params.userAuth.firebaseUser?.refreshToken ??
							localStorageUser.refreshToken,
						status: 'authenticated',
						role: localStorageUser.role
					};
				}
				return user;
			}

			return params.userAuth as IUserSlice;
        } catch (error: any) {
            return rejectWithValue(error.message)
        }
    }
);