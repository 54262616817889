import { CssBaseline } from '@mui/material';
import * as Sentry from '@sentry/react';
import { wrapUseRoutes } from '@sentry/react';
import { useRoutes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import router from 'src/router/router';
import { sentryConfig } from './config/sentry';
import DialogProvider from './context/dialogContext';
import Status500 from './components/pages/Status/Status500/Status500';
import { useAuth } from './features/authentication';
import { SuspenseLoader, ThemeProviderWrapper, initMonitoring } from 'features';
import { Suspense } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';

process.env.REACT_APP_DEPLOYMENT_ENV === 'local'
	? console.log('not adding sentry since in development')
	: Sentry.init(sentryConfig);
const useSentryRoutes = wrapUseRoutes(useRoutes);

function App() {
	const content = useSentryRoutes(router);

	const { loading, user } = useAuth();

	// if the url params contains an idToken, save it to localStorage
	const urlParams = new URLSearchParams(window.location.search);
	const idToken = urlParams.get('idToken');
	if (idToken) {
		localStorage.setItem('idToken', decodeURIComponent(idToken));
	}

	return loading && !user ? (
		<ThemeProviderWrapper>
			<Suspense fallback={<SuspenseLoader />}>
				<SuspenseLoader />
			</Suspense>
		</ThemeProviderWrapper>
	) : (
		<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
			<ThemeProviderWrapper>
				<Sentry.ErrorBoundary
					fallback={
						<Status500 resetErrorBoundary={() => window.location.reload()} />
					}
				>
					<CssBaseline />
					<DialogProvider>
						<ToastContainer
							hideProgressBar={true}
							theme={'light'}
							position="bottom-left"
						/>
						{content}
					</DialogProvider>
				</Sentry.ErrorBoundary>
			</ThemeProviderWrapper>
		</LocalizationProvider>
	);
}

export default App;
