import { SuspenseLoader } from 'features';
import { Suspense, useEffect } from 'react';
import { useAuth } from 'src/features/authentication';
import { logoutFirebase } from 'src/features/authentication/clients/firebase/FirebaseAuthProvider';
import { persistAuth } from 'src/features/authentication/utils/persistAuth';
import { useAppDispatch, useAppSelector } from 'src/hooks/hooks';
import { IUserSlice } from 'src/store/user/userSlice.contracts';

export default function AuthGuard({ children }: { children: JSX.Element }) {
	try {
		const userSlice: IUserSlice = useAppSelector((state) => state.user);
		const { loading, error, user, emailVerified, getTokens, login, logout } = useAuth();
		const dispatch = useAppDispatch();

		useEffect(() => {
			const refreshTokens = async () => {
				try {
					const tokens = await getTokens();
					const userSlice: Partial<IUserSlice> = {
						firebaseUser: user,
						status: 'authenticated',
						accessToken: tokens?.accessToken ?? null,
						refreshToken: tokens?.refreshToken ?? null,
					};
					dispatch(persistAuth({ userAuth: userSlice }));
				} catch (error) {
					logout();
				}
			};

			// if user is authenticated in firebase, fetch user from firebase and persist user in storage
			if (user && !loading) {
				refreshTokens();
			}

			// if there is a firebase error, log out
			if (error) {
				logout();
			}
		}, [user, loading, error]);

		// if firebase is loading, show suspenseLoader
		if (loading ) {
			return (
				<Suspense fallback={<SuspenseLoader />}>
					<SuspenseLoader />
				</Suspense>
			);
		}

		// if user is not authenticated in firebase, check whether an auth token is present in Local storage.
		// if present, log user in; if not, log out and redirect to intelekt-auth
		if (!user && !loading) {
			if (!userSlice || !userSlice?.firebaseUser) {
				const token = localStorage.getItem('idToken');

				if (typeof token !== undefined || token?.length !== 0) {
					login(token);
				} else {
					logout();
				}
			}
		}

	} catch (error) {
		// if anything failed in AuthGuard then redirect to intelekt-auth
		logoutFirebase();
	}

	return children;
}