import { Stack } from '@mui/material';
import { CustomTextInput } from 'features';
import { UserDepot } from 'src/services/rtkQuery/types/user.types';

interface MyDepotProps {
    depot: UserDepot | undefined;
};

export const MyDepot = ({ depot }: MyDepotProps) => {
    return (
        <Stack gap={1} pb={2}>
            <Stack direction='row' justifyContent='space-between'>
                <CustomTextInput
                    label='Telephone'
                    disabled={true}
                    value={depot?.telephone}
                    sx={{ width: '265px' }}
                />
                <CustomTextInput
                    label='Address'
                    disabled={true}
                    value={depot?.address}
                    sx={{ width: '265px' }}
                />
            </Stack>
            <Stack direction='row' justifyContent='space-between'>
                <CustomTextInput
                    label='City'
                    disabled={true}
                    value={depot?.city}
                    sx={{ width: '265px' }}
                />
                <CustomTextInput
                    label='Province'
                    disabled={true}
                    value={depot?.province}
                    sx={{ width: '265px' }}
                />
            </Stack>
            <Stack direction='row' justifyContent='space-between'>
                <CustomTextInput
                    label='Country'
                    disabled={true}
                    value={depot?.country}
                    sx={{ width: '265px' }}
                />
                <CustomTextInput
                    label='Postal Code'
                    disabled={true}
                    value={depot?.postalCode}
                    sx={{ width: '265px' }}
                />
            </Stack>
        </Stack>
    );
};