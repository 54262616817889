import { Suspense, lazy, FC } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';
import pages from './routes';
import AuthGuard from 'src/Guards/authGuard/AuthGuard';
import BaseLayout from 'src/components/organisms/BaseLayout';
import { SuspenseLoader } from 'features';

const Loader = (Component: FC) => (props: any) =>
	(
		<Suspense fallback={<SuspenseLoader />}>
			<Component {...props} />
		</Suspense>
	);

// Pages
const Clients = Loader(
	lazy(() => import('src/components/pages/Clients/Clients'))
);
const ClientDashBoard = Loader(
	lazy(() => import('src/components/pages/ClientDashBoard/ClientDashboard'))
);
const FarmDashBoard = Loader(
	lazy(() => import('src/components/pages/FarmDashBoard/FarmDashboard'))
);
const Recommendations = Loader(
	lazy(() => import('src/components/pages/Recommendations'))
);
const RecommendationStepper = Loader(
	lazy(() => import('src/components/pages/RecommendationsStepper'))
);
const PriceList = Loader(
	lazy(() => import('src/components/pages/PriceList/PriceList'))
);
const FieldAssessmentLog = Loader(
	lazy(() => import('src/components/pages/FieldAssessmentLog/index'))
);
const FieldAssessmentStepper = Loader(
	lazy(() => import('src/components/pages/FieldAssessmentStepper/index'))
);

// Recommendation Acknowledge page
const RecommendationAcknowledge = Loader(lazy(() => import('src/components/pages/RecommendationAcknowledge/RecommendationAcknowledge')));

//Recipes
const Recipes = Loader(lazy(() => import('src/components/pages/Recipes')));
const RecipeStepper = Loader(
	lazy(() => import('src/components/pages/RecipeStepper'))
);

//Library
const LibraryDataScreen = Loader(
	lazy(() => import('src/components/pages/Library/LibraryDataScreen'))
);
const CropProtectionScreen = Loader(
	lazy(() => import('src/components/pages/Library/CropProtectionScreen'))
);
const PlantNutritionScreen = Loader(
	lazy(() => import('src/components/pages/Library/PlantNutritionScreen'))
);
const SeedsScreen = Loader(
	lazy(() => import('src/components/pages/Library/SeedsScreen'))
);
const ViewDocument = Loader(
	lazy(() => import('features/src/organisms/Library/ViewDocument'))
);

// Status
const SystemStatus = Loader(
	lazy(() => import('src/components/pages/Status/System'))
);
const Status404 = Loader(
	lazy(() => import('src/components/pages/Status/Status404/Status404'))
);
const Status500 = Loader(
	lazy(() => import('src/components/pages/Status/Status500/Status500'))
);
const StatusComingSoon = Loader(
	lazy(() => import('src/components/pages/Status/ComingSoon/ComingSoon'))
);
const StatusMaintenance = Loader(
	lazy(() => import('src/components/pages/Status/Maintenance/Maintenance'))
);
const StatusSuccess = Loader(
	lazy(() => import('src/components/pages/Status/Success/Success'))
);
const StatusFailure = Loader(
	lazy(() => import('src/components/pages/Status/Failure/Failure'))
);
const StatusCancel = Loader(
	lazy(() => import('src/components/pages/Status/Cancel/Cancel'))
);

const routes: RouteObject[] = [
	{
		/**
		 * Basic container element without a Sidebar, top Navbar, or authentication.
		 */
		path: '',
		element: '',
		children: [
			// BASE
			{
				path: '/',
				element: <Navigate to={pages.clients.userClients.path} replace />,
			},
			// STATUS
			{
				path: pages.status.root,
				children: [
					{
						path: '',
						element: <Navigate to="404" replace />,
					},
					{
						path: pages.status.systemStatus.path,
						element: (
							<AuthGuard>
								<SystemStatus />
							</AuthGuard>
						),
					},
					{
						path: pages.status.status404.path,
						element: <Status404 />,
					},
					{
						path: pages.status.status500.path,
						element: <Status500 />,
					},
					{
						path: pages.status.statusMaintenance.path,
						element: <StatusMaintenance />,
					},
					{
						path: pages.status.statusComingSoon.path,
						element: <StatusComingSoon />,
					},
					{
						path: pages.status.statusSuccess.path,
						element: <StatusSuccess />,
					},
					{
						path: pages.status.statusFailure.path,
						element: <StatusFailure />,
					},
					{
						path: pages.status.statusCancel.path,
						element: <StatusCancel />,
					},
				],
			},
			// NOT FOUND
			{
				path: '*',
				element: <Status404 />,
			},
			// Recommendation Thank You Page
			{
				path: pages.recommendations.recommendationAcknowledge.path,
				element: <RecommendationAcknowledge/>
			}
		],
	},
	{
		/**
		 * All children with this element will have a Sidebar and top Navbar
		 * AuthGuard checks that the user is logged in before granting access to its children pages
		 */
		path: '',
		element: (
			<AuthGuard>
				<BaseLayout />
			</AuthGuard>
		),
		children: [
			{
				path: '/',
				element: <Navigate to={pages.clients.root} replace />,
			},
			{
				path: pages.clients.root,
				children: [
					{
						path: pages.clients.userClients.path,
						element: <Clients />,
					},
					{
						path: pages.clients.clientDashboard.path,
						element: <ClientDashBoard />,
					},
					{
						path: pages.clients.farmDashboard.path,
						element: <FarmDashBoard />,
					},
				],
			},
			{
				path: pages.recommendations.root,
				children: [
					{
						path: pages.recommendations.allRecommendations.path,
						element: <Recommendations />,
					},
					{
						path: pages.recommendations.recommendationDetail.path,
						element: <RecommendationStepper />,
					},
				],
			},
			{
				path: pages.recipes.root,
				children: [
					{
						path: pages.recipes.allRecipes.path,
						element: <Recipes />,
					},
					{
						path: pages.recipes.recipeDetail.path,
						element: <RecipeStepper />,
					},
				],
			},
			{
				path: pages.fieldAssessment.root,
				children: [
					{
						path: pages.fieldAssessment.fieldAssessmentLog.path,
						element: <FieldAssessmentLog />,
					},
					{
						path: pages.fieldAssessment.createFieldAssessment.path,
						element: <FieldAssessmentStepper />,
					},
				],
			},
			{
				path: pages.priceList.path,
				element: <PriceList />,
			},
			{
				path: pages.library.root,
				children: [
					{
						path: pages.library.library.path,
						element: <LibraryDataScreen />,
					},
					{
						path: pages.library.cropProtection.path,
						element: <CropProtectionScreen />,
					},
					{
						path: pages.library.plantNutrition.path,
						element: <PlantNutritionScreen />,
					},
					{
						path: pages.library.seeds.path,
						element: <SeedsScreen />,
					},
				],
			},
			{
				path: pages.document.path,
				element: <ViewDocument />,
			},
			{
				path: '*',
				element: <Status404 />,
			},
		],
	},
];

export default routes;
