import { useState } from 'react';
import { AppBar, Box, useTheme } from '@mui/material';
import { UserContainer } from './UserContainer';
import {
	HeaderWrapper,
	StyledDivider,
} from './Header.styles';
import { Breadcrumbs, Button, Icon } from 'features';
import { QuestionModal } from '../QuestionModal/QuestionModal';

const Header = () => {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const theme = useTheme();

	const drawerWidth  = theme.sidebar.width;

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	return (
		<>
			<AppBar
				position='fixed'
				color='default'
				elevation={0}
				sx={{ width: `calc(100% - ${drawerWidth})`, ml: drawerWidth }}>
				<HeaderWrapper>
					<Breadcrumbs separator={<Icon icon='UilAngleRight' />} />
					<Box display={'flex'} alignItems={'center'} gap={'10px'}>							
							<Button
								variant='contained'
								color='primary'
								endIcon={'UilQuestionCircle'}
								size='small'
								onClick={openModal}>
								Ask a question
							</Button> 
						<StyledDivider orientation='vertical' />
						<UserContainer
							menuItems={[
								{
									key: '1',
									children: 'Edit Profile',
									icon: 'UilPen',
								},
								{
									key: '2',
									children: 'Logout',
									icon: 'UilSignout',
								},
							]}
						/>
					</Box>
				</HeaderWrapper>
			</AppBar>
			<QuestionModal
				closeModal={closeModal}
				modalState={isModalOpen}
				openModal={openModal}
			/>
		</>
	);
};

export default Header;
