import { baseApi } from '../baseApi.service';
import { RestApiResponseWithData } from '../types/apiResponse.types';
import { UserProfile } from '../types/user.types';
import { Endpoint } from '../config/apiTags';

const userProfileApi = baseApi.injectEndpoints({
	endpoints: (builder) => ({
		getUserProfile: builder.query<UserProfile, void>({
			query: () => 'User/Profile',
			transformResponse: (response: {data: UserProfile}) => response.data,
            providesTags: [Endpoint.USERPROFILE]
		}),

        updateUserProfile: builder.mutation<RestApiResponseWithData<UserProfile>, Partial<UserProfile>>({
			query(body) {
				return {
					url: 'User/Profile',
					method: 'PATCH',
					body,
				};
			},
			invalidatesTags: [Endpoint.USERPROFILE]
		}),
    }),
});

export const { 
    useLazyGetUserProfileQuery,
    useUpdateUserProfileMutation
} = userProfileApi;