import { toast } from 'react-toastify';
import { app } from 'src/features/authentication/clients/firebase/FirebaseAuthProvider';
import {
	deleteObject,
	getBlob,
	getDownloadURL,
	getStorage,
	listAll,
	ref,
	uploadBytes,
} from 'firebase/storage';
import { blobToFile } from 'features';

const storage = getStorage(app);

/**
 * Uploads a single image to Firebase Storage and returns the url to that image.
 *
 * @param file - The image to upload
 * @param pathReference - The Firebase Storage folder location, e.g. 'Users/{id}/Questions/{id}'
 * @param fileName - optional name of the file to upload
 * @returns the url where the image is stored
 *
 */
export const uploadFileToFirebase = async (
	file: File,
	pathReference: string,
	fileName?: string
): Promise<string> => {
	try {
		const name = fileName ? fileName : file.name;
		const storageRef = ref(storage, pathReference + '/' + name);
		await uploadBytes(storageRef, file);
		const url = await getDownloadURL(storageRef);
		return url;
	} catch (error: any) {
		toast.error(error);
		return '';
	}
};

/**
 * Uploads multiple images to Firebase Storage and returns a list of urls to those images.
 *
 * @param files - The images to upload
 * @param pathReference - The Firebase Storage folder location, e.g. 'Users/{id}/Questions/{id}'
 * @returns a list of urls where the images are stored
 *
 */
export const uploadFilesToFirebase = async (
	files: File[],
	pathReference: string
): Promise<string[]> => {
	try {
		const urls: string[] = [];
		const promises = files.map(async (file) => {
			const storageRef = ref(storage, pathReference + '/' + file.name);
			await uploadBytes(storageRef, file);
			urls.push(await getDownloadURL(storageRef));
		});

		await Promise.all(promises);
		return urls;
	} catch (error: any) {
		toast.error(error);
		return [];
	}
};

/**
 * Downloads a single file from Firebase Storage and returns as a File object
 *
 * @param pathReference - The Firebase Storage image location, e.g. 'Users/{id}/ProfilePic.png'
 * @returns a File
 *
 */
export const getFileFromFirebase = async (
	pathReference: string
): Promise<File | null> => {
	try {
		const storageRef = ref(storage, pathReference);
		const blob = await getBlob(storageRef);
		return blobToFile(blob, storageRef.name);
	} catch (error: any) {
		toast.error(error);
		return null;
	}
};

/**
 * Downloads multiple Files from Firebase Storage and returns a list of Files.
 *
 * @param pathReference - The Firebase Storage folder location, e.g. 'Users/{id}/Questions/{id}'
 * @returns a list of Files
 *
 */
export const getFilesFromFirebase = async (
	pathReference: string
): Promise<File[]> => {
	try {
		const storageRef = ref(storage, pathReference);

		// get list of images references in specified folder
		const response = await listAll(storageRef);

		const files: File[] = [];
		if (response !== undefined && response.items.length > 0) {
			const promises = response.items.map(async (itemRef) => {
				const blob = await getBlob(itemRef);
				files.push(blobToFile(blob, itemRef.name));
			});
			await Promise.all(promises);
		}
		return files;
	} catch (error: any) {
		toast.error(error);
		return [];
	}
};

/**
 * Gets the URL of a file in the specified Firebase Storage location
 *
 * @param pathReference - The Firebase Storage file location, e.g. 'Users/{id}/ProfilePic.png'
 * @returns a list of urls
 *
 */
export const getUrlFromFirebase = async (
	pathReference: string
): Promise<string | null> => {
	try {
		const storageRef = ref(storage, pathReference);
		const url = await getDownloadURL(storageRef);
		return url;
	} catch (error: any) {
		toast.error(error);
		return null;
	}
};

/**
 * Gets the URLs of all files in the specified Firebase Storage folder
 *
 * @param pathReference - The Firebase Storage folder location, e.g. 'Users/abc/Questions/123'
 * @returns a list of urls
 *
 */
export const getUrlsFromFirebase = async (
	pathReference: string
): Promise<string[]> => {
	try {
		const storageRef = ref(storage, pathReference);

		// get list of images references in specified folder
		const response = await listAll(storageRef);

		const urls: string[] = [];
		if (response !== undefined && response.items.length > 0) {
			const promises = response.items.map(async (itemRef) => {
				urls.push(await getDownloadURL(itemRef));
			});
			await Promise.all(promises);
		}
		return urls;
	} catch (error: any) {
		toast.error(error);
		return [];
	}
};

/**
 * Delete File from Firebase Storage.
 *
 * @param pathReference - The Firebase Storage folder location, e.g. 'Users/{id}/Questions/{id}'
 * @param fileNames - Array of filoe names to delete
 * @returns a list of Files
 *
 */
export const deleteFilesFromFirebase = async (
	pathReference: string,
	fileNames: string[]
): Promise<void> => {
	try {
		const promises = fileNames.map(async (fileName) => {
			const storageRef = ref(storage, `${pathReference}/${fileName}`);
			const blob = await getBlob(storageRef);
			if (blob) deleteObject(storageRef);
		});
		await Promise.all(promises);
	} catch (error: any) {
		toast.error(error);
	}
};

/**
 * Deletes all files in the specified Firebase Storage location, including files contained in subfolders.
 *
 * @param pathReference - The Firebase Storage file location, e.g. 'Users/{id}/Questions/{id}'
 */
export const deleteAllInReference = async (pathReference: string): Promise<void> => {
	try {
  
	  const storageRef = ref(storage, pathReference);
  
	  const response = await listAll(storageRef);
	  if (response !== undefined) {
		if (response.items.length > 0) {
		  const promises = response.items.map(async itemRef => {
			const fileRef = ref(storage, itemRef.fullPath);
			await deleteObject(fileRef);
		  })
		  await Promise.all(promises);
		}
  
		if (response.prefixes.length > 0) {
		  // call this function recursively for all subfolders
		  const promises = response.prefixes.map(async folderRef => {
			await deleteAllInReference(folderRef.fullPath);
		  })
		  await Promise.all(promises);
		}
	  }
	} catch (error: any) {
	  toast.error(error);
	}
  };
