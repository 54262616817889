export interface Question {
	questionId?: string;
	type: string;
	subject: string;
	message: string;
	hasImages: boolean;
	files?: Blob[];
	platform: Platform;
}

export enum Platform {
	TMS = 0,
	CA = 1,
	ForU = 2,
}