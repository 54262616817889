import { IconProps } from 'features';
import pages from 'src/router/routes';

export type SidebarTitleProps = {
	title: string;
	icon: IconProps['icon'];
	path: string;
	children?: SidebarTitleProps[];
	open?: boolean;
	caslItem?: string;
};

export const SidebarTitles = (adminUser: boolean): SidebarTitleProps[] => {
	const titles: SidebarTitleProps[] = [
		{
			title: 'Clients',
			caslItem: 'clients',
			icon: 'UilUsersAlt',
			open: false,
			path: pages.clients.userClients.path,
		},
		{
			title: 'Price List',
			caslItem: 'priceList',
			icon: 'UilTagAlt',
			open: false,
			path: pages.priceList.path,
		},
		{
			title: 'Recommendations',
			caslItem: 'recommendations',
			icon: 'UilCommentAltEdit',
			open: false,
			path: pages.recommendations.allRecommendations.path,
		},
		{
			title: 'Field Assessments',
			caslItem: 'fieldAssessmentLog',
			icon: 'UilImageSearch',
			open: false,
			path: pages.fieldAssessment.fieldAssessmentLog.path,
		},
		{
			title: 'Recipes',
			caslItem: 'recipes',
			icon: 'UilFlask',
			open: false,
			path: pages.recipes.allRecipes.path,
		},
		{
			title: 'Library',
			caslItem: 'library',
			icon: 'UilBooks',
			open: false,
			path: pages.library.library.path,
		},
	];

	return titles;
};
