import { baseApi } from '../baseApi.service';
import { Question } from '../types/question.types';

const questionApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        createQuestion: builder.mutation<void, Question>({
            query(params) {
                const data = new FormData();
                data.append('type', params.type as string);
                data.append('subject', params.subject as string);
                data.append('message', params.message as string);
                data.append('hasImages', params.hasImages?.toString() as string);
                data.append('platform', params.platform?.toString() as string);

                params.files?.forEach((file) => {
                    //@ts-ignore
                    data.append('files', file as Blob, file.name);
                });

                return {
                    url: 'Question',
                    method: 'POST',
                    body: data,
                    formData: true,
                };
            },
        }),
    })
})

export const {
    useCreateQuestionMutation,
} = questionApi;