import { configureStore, combineReducers, AnyAction, ThunkAction, Action } from '@reduxjs/toolkit';
import userSlice from './user/userSlice';
import { baseApi } from 'src/services/rtkQuery/baseApi.service';
import themeSlice from './theme/themeSlice';

const combinedReducer = combineReducers({
  theme: themeSlice,
  user: userSlice,
  [baseApi.reducerPath]: baseApi.reducer,
});

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === 'user/resetUser') {
    // Reset the Redux store
    state = undefined;
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  devTools: process.env.REACT_APP_DEPLOYMENT_ENV !== 'production',
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
    .concat(baseApi.middleware,),
});

export default store;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
