const pages = {
	clients: {
		// This route displays all the clients associated with the logged in user
		// It is the default route (homepage) when logged in to the CA App
		root: 'clients',
		userClients: {
			name: 'user-clients',
			path: '/clients/',
		},
		clientDashboard: {
			name: 'client-dashboard',
			path: '/clients/client-dashboard',
		},
		farmDashboard: {
			name: 'farm-dashboard',
			path: '/clients/client-dashboard/farm-dashboard',
		},
	},
	recommendations: {
		root: 'recommendations',
		allRecommendations: {
			name: 'all-recommendations',
			path: '/recommendations/',
		},
		recommendationDetail: {
			name: 'create-recommendation',
			path: '/recommendations/detail',
		},
		recommendationAcknowledge: {
			name: 'recommendation-acknowledge',
			path: '/recommendations/acknowledge'
		}
	},
	recipes: {
		root: 'recipes',
		allRecipes: {
			name: 'all-recipes',
			path: '/recipes/',
		},
		recipeDetail: {
			name: 'recipes-detail',
			path: '/recipes/detail/',
		},
	},
	fieldAssessment: {
		root: 'field-assessments',
		fieldAssessmentLog: {
			name: 'field-assessments',
			path: '/field-assessments',
		},
		createFieldAssessment: {
			name: 'create-field-assessment',
			path: '/field-assessments/create-field-assessment/',
		},
	},
	priceList: {
		name: 'price-list',
		path: '/price-list/',
	},
	library: {
		root: 'library',
		library: {
			name: 'library',
			path: '/library',
		},
		cropProtection: {
			name: 'crop-protection',
			path: '/library/crop-protection',
		},
		plantNutrition: {
			name: 'plant-nutrition',
			path: '/library/plant-nutrition',
		},
		seeds: {
			name: 'seeds',
			path: '/library/seeds',
		},
	},
	document: {
		name: 'view-document',
		path: '/document/',
	},
	status: {
		root: 'status',
		unverified: {
			name: 'unverified',
			path: '/status/unverified/',
		},
		statusComingSoon: {
			name: 'coming-soon',
			path: '/status/coming-soon/',
		},
		statusMaintenance: {
			name: 'maintenance',
			path: '/status/maintenance/',
		},
		status404: {
			name: '404',
			path: '/status/404/',
		},
		status500: {
			name: '500',
			path: '/status/500/',
		},
		statusSuccess: {
			name: 'success',
			path: '/status/success/',
		},
		statusFailure: {
			name: 'failure',
			path: '/status/failure/',
		},
		statusCancel: {
			name: 'cancel',
			path: '/status/cancel/',
		},
		systemStatus: {
			name: 'system-status',
			path: '/status/system-status/',
		},
	},
};

export default pages;
