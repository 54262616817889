import {
	Avatar,
	Box,
	DialogActions,
	DialogTitle,
	Typography,
	styled,
	Dialog,
	Tabs,
	Tab,
} from '@mui/material';
import { ChangeEvent, useState, useEffect, useRef } from 'react';
import { Icon } from 'features/src/atoms/Icon/Icon';
import { IconButton } from 'features/src/atoms/IconButton/IconButton';
import { updateProfile } from 'firebase/auth';
import { LoadingButton } from 'features';
import { toast } from 'react-toastify';
import { useAuth } from 'src/features/authentication';
import { useLazyGetUserProfileQuery, useUpdateUserProfileMutation } from 'src/services/rtkQuery/endpoints/userProfileApi.service';
import { Form, Formik, FormikValues } from 'formik';
import { UserProfile } from 'src/services/rtkQuery/types/user.types';
import * as Yup from 'yup';
import { getUrlFromFirebase, uploadFileToFirebase } from 'src/services/firebase/firebaseStorage';
import { MyProfile } from './MyProfile';
import { MyDepot } from './MyDepot';
import { useAppSelector } from 'src/hooks/hooks';
import { selectUser } from 'src/store/user/userSlice';

export const PopoverWrapper = styled(Box)(({ theme }) => ({
	width: '100%',
	padding: '0px',
	display: 'flex',
	flexDirection: 'column',
}));

const validationSchema = Yup.object({
	basosId: Yup.string().optional(),
	cropLifeSaId: Yup.string().optional(),
})

interface ProfileModalProps {
	open: boolean;
	handleClose: () => void;
}

export interface ImageObj {
	url: string;
	file: File | null;
};

export const ProfileModal = ({
	open,
	handleClose,
}: ProfileModalProps) => {
	const [avatar, setAvatar] = useState<ImageObj>({ url: '', file: null });
	const [signature, setSignature] = useState<ImageObj>({ url: '', file: null });
	const uploadedSignature = useRef<string | null>(null);
	const [tabValue, setTabValue] = useState(0);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [data, setData] = useState<UserProfile | undefined>();

	const user = useAppSelector(selectUser);
	const { user: firebaseUser } = useAuth();
	const userFolder = `Users/${user.firebaseUser?.uid}`;

	const [ getUserProfile ] = useLazyGetUserProfileQuery();

	const [ updateUserProfile, {isSuccess} ] = useUpdateUserProfileMutation();

	useEffect(() => {
		const getSignature = async () => {
			const signatureUrl = await getUrlFromFirebase(userFolder + '/Signature.png');
			if (signatureUrl) {
				uploadedSignature.current = signatureUrl;
				setSignature({
					url: signatureUrl,
					file: null
				});
			}
		};

		const getUserProfileData = async () => {
			const { data } = await getUserProfile();
			setData(data);
		};

		// once auth has loaded, get userdata and set the avatar and signature image urls
		if (user.firebaseUser) {
			getUserProfileData();
			setAvatar({
				url: user.firebaseUser?.photoURL ?? '',
				file: null
			});
			getSignature()
		};
	}, [user.firebaseUser]);

	const initialValues: Partial<UserProfile> = {
		basosId: data?.basosId,
		cropLifeSaId: data?.cropLifeSaId,
	};

	const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		const selectedFile = e.target.files?.[0];
		if (selectedFile) {
			const url = URL.createObjectURL(selectedFile);
			setAvatar({
				url: url,
				file: selectedFile
			});
		}
	};

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setTabValue(newValue);
	};

	const handleSaveSignature = (file: File) => {
		const url = URL.createObjectURL(file);
		setSignature({ url: url, file: file })
	};

	const clearAndClose = () => {
		handleClose();
		setAvatar({
			url: user.firebaseUser?.photoURL ?? '',
			file: null
		});
		setSignature({
			url: uploadedSignature.current ?? '',
			file: null
		});
	};

	const handleSave = async (values: FormikValues) => {
		setIsSubmitting(true);
		try {

			if (firebaseUser) {
				if (avatar.file) {
					// save file to firebase storage
					const url = await uploadFileToFirebase(avatar.file, userFolder, 'ProfilePic.png');
					// update profileURL on firebase user:
					await updateProfile(firebaseUser, { photoURL: url });
				}
				if (signature.file) {
					// save file to firebase storage
					const url = await uploadFileToFirebase(signature.file, userFolder, 'Signature.png');
					// update signature ref
					uploadedSignature.current = url;
				}
			}

			await updateUserProfile({
				basosId: values.basosId,
				cropLifeSaId: values.cropLifeSaId,
			}).then(() => {
				if (isSuccess) toast.success('Profile saved')
			});

		} catch (error: any) {
			toast.error(error);
		} finally {
			setIsSubmitting(false);
			clearAndClose();
		}
	};

	return (
		<Dialog
			onClose={clearAndClose}
			open={open}
			fullWidth
			maxWidth='sm'
		>
			<PopoverWrapper>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={async (values) => await handleSave(values)}
					enableReinitialize
				>
					{({ values }) => (
						<Form>
							<DialogTitle
								display='flex'
								flexDirection='row'
								alignItems='center'
								gap='12px'
								sx={{
									justifyContent: 'space-between',
									alignItems: 'flex-start',
									padding: '26px 26px 14px 26px',
								}}>
								<Box
									display='flex'
									flexDirection='row'
									alignItems='center'
									gap='12px'>
									<Icon icon='UilEditAlt' />
									<Typography fontWeight={700} fontSize='24px'>
										Edit Profile
									</Typography>
								</Box>
								<Box
									sx={{ display: 'flex', flexDirection: 'row', alignItems: 'end' }}>
									<Avatar
										sx={{ width: '94px', height: '94px', right: -40 }}
										alt=''
										src={avatar.url ?? user.firebaseUser?.photoURL}
									/>
									<input
										accept='image/*'
										style={{ display: 'none' }}
										id='avatar-input'
										type='file'
										onChange={handleFileChange}
									/>
									<IconButton
										color='inherit'
										icon='UilEditAlt'
										onClick={() => document.getElementById('avatar-input')?.click()}
										sx={{
											bottom: -10,
											right: 0,
											position: 'relative',
											bgcolor: (theme) => theme.palette.primary.main,
											color: 'white',
											'&:hover': {
												color: 'white',
												bgcolor: (theme) => theme.palette.primary.dark,
											},
											zIndex: 1,
										}}
									/>
								</Box>
							</DialogTitle>
							<Tabs
								value={tabValue}
								onChange={handleTabChange}
								variant='fullWidth'
								sx={{ mt: -3}}
							>
								<Tab label='My Profile' disableRipple sx={{alignItems:'flex-start'}}/>
								<Tab label='My Depot' disableRipple sx={{alignItems:'flex-start'}}/>
							</Tabs>
							<Box sx={{ p: 3 }}>
								{ 
									tabValue === 0 && 
										<MyProfile 
											userData={data} 
											signatureUrl={signature.url}
											onSubmitSignature={handleSaveSignature}
										/>
								}
								{ 
									tabValue === 1 && 
										<MyDepot 
											depot={data?.depots[0]}
										/>
								}
							</Box>
							<DialogActions
								sx={{ justifyContent: 'center', padding: '0px 60px 26px 60px' }}>
								<LoadingButton
									loading={isSubmitting}
									variant='outlined'
									color='secondary'
									fullWidth
									onClick={clearAndClose}>
									Cancel
								</LoadingButton>
								<LoadingButton
									loading={isSubmitting}
									type='submit'
									variant='contained'
									endIcon='UilSave'
									fullWidth
									onClick={async () => await handleSave(values)}>
									Save Profile
								</LoadingButton>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</PopoverWrapper>
		</Dialog>
	);
};
